<template>
  <aside id="celebration-details-modal" class="modal-window">
    <header>
      <h2>Celebration Details</h2>
      <a href="#" @click.prevent="$emit('close')" class="close"><fa-icon icon="times" /></a>
    </header>

    <div class="scroll-view" v-if="celebration !== null">
      <div class="header">
        <div class="title">
          <profile-picture :user="celebration.user" height="80" />
          <div class="name-wrap">
            <small>Birthday donation for</small>
            <name :user="celebration.user" full />
          </div>
        </div>
        <div class="amount">
          <span>{{$formatMoney(celebration.main_donation.currency_amount)}}</span>
          <router-link v-if="!!celebration.main_donation.statement_id" :to="{name: 'statement-details', params:{statementId: celebration.main_donation.statement_id}}">View statement</router-link>
        </div>
      </div>

      <div class="details">
        <div><strong>Donation ID: </strong>{{celebration.main_donation.id}}</div>
        <div><strong>Date: </strong>{{formatDate(celebration.created_at)}}</div>
        <div><strong>Charity: </strong>{{celebration.main_donation.charity_details.name}} ({{$formatEin(celebration.main_donation.charity_details.ein)}})</div>
        <div>
          <strong>Statuses: </strong>
          <ul>
            <li v-for="status in celebration.main_donation.statuses" :key="`status_${status.ts}`">
              <span>{{formatDate(status.ts)}}</span>
              <h4>{{statusText(status.status)}}</h4>
            </li>
          </ul>
        </div>
        
      </div>

      <div class="pitchins">
        <h4>Pitch In Donations</h4>
        <ul v-if="celebration.pitchins.length > 0">
          <li v-for="pitchin in celebration.pitchins" :key="`pitchin_${pitchin.id}`">
            <div class="name">
              <profile-picture :user="pitchin.user" />
              <div>
                <span class="timestamp">{{formatDate(pitchin.created_at)}}</span>
                <name full :user="pitchin.user" />
              </div>
            </div>

            <div class="amount"><span>{{$formatMoney(pitchin.currency_amount)}}</span></div>
          </li>
        </ul>

        <div class="empty-state" v-else>
          No team pitch in donations for this celebration.
        </div>
      </div>
    </div>
    <loading-indicator v-else />
  </aside>
</template>

<script>
import {DateTime} from 'luxon'
export default {
  name: 'celebration-details-modal',
  data() {
    return {
      celebration: null
    }
  },
  mounted() {
    if( this.$route.params.celebrationId ) {
      this.populateCelebration(this.$route.params.celebrationId)
    }
  },
  methods: {
    async populateCelebration(id) {
      const resp = await this.$api.Contributions.get_celebration(id)

      this.celebration = resp;
    },
    formatDate(d) {
      const date = DateTime.fromISO(d, {zone: 'utc'})

      return date.toLocaleString(DateTime.DATE_FULL)
    },
    statusText(status) {
      switch(status) {
        case "PENDING":
          return 'This donation will be available on your next donation statement.';
        break;
        case "PROCESSING":
          return `We're waiting for this donation statement to be paid.`;
        break;
        case "COMPLETE":
          return 'Donation funds have been received and disbursed to the charity.';
        break;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  margin: 0 0 25px;

  .title {
    display: flex;
    flex: 1;
    align-items: center;

    >.name-wrap {
      margin: 0 0 0 15px;
      > small {
        display: block;
        font-size: 16px;
        color: #5c5c5c;
        line-height: 16px;
      }

      .cupcake-name {
        font-size: 26px;
        margin: 3px 0 0;
        display: block;
        line-height: 26px;
      }
    } 
  }

  .amount {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    > span {
      background: $green;
      border-radius: 100em;
      padding: 6px 10px;
      display: block;
      font-size: 18px;
    }

    > a {
      display:block;
      margin: 5px 0 0;
      font-size: 14px;
      color: $red;
    }
  }
}

.pitchins {
  h4 {
    font-weight: 700;
    font-size: 18px;
    margin: 0 0 10px;
  }

  ul {
    display: block;
    margin: 0 -25px 25px 0;
    padding: 0 0 0 0;

    > li {
      display: block;
      padding: 7px 25px 7px 0;
      border-bottom: 1px solid #e7ded5;
      display: flex;
      align-items: center;

      .name {
        display: flex;
        align-items: center;

        > div {
          &.profile-picture {
            margin: 0 10px 0 0;
          }

          >span {
            display: block;

            &.timestamp {
              font-size: 12px;
              color: #5c5c5c;
              line-height: 12px;
            }

            &.cupcake-name {

            }
          }
        }
      }

      .amount {
        margin: 0 0 0 auto;
        >span {
          background: $green;
          border-radius: 100em;
          padding: 4px 10px;
          display: block;
          font-size: 14px;
        }
      }
    }
  }
}

.details {
  margin: 0 -25px 35px 0;
  padding: 0 0 0 0;
  >div {
    padding: 7px 25px 7px 0;
    border-bottom: 1px solid #e7ded5;
    font-size: 14px;
    line-height: 16px;

    &:last-child {
      border-bottom: none;
    }

    >strong {
      width: 200px;
      display: inline-block;
      vertical-align: top;
    }

    >ul {
      display: inline-block;

      > li {
        display: block;
        padding: 0 0 0 15px;
        position: relative;

        > span {
          display: block;
          font-size: 12px;
          color: #5c5c5c;
        }

        &+li {
          margin: 10px 0 0;
        }

        &:before {
          content: '';
          display: block;
          border-radius: 100%;
          width: 7px;
          height: 7px;
          background: $red;
          position: absolute;
          left: 0;
          top: 4px;
          z-index: 2;
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          left: 3px;
          top: 5px;
          bottom: -15px;
          background: $red;
          width: 1px;
        }

        &:last-child {
          &:after {
            content: none;
          }
        }
      }
    }
  }
}
</style>